<template>
  <footer
    class="w-full bg-realBlack text-white pt-[12vh] pb-6 relative md:border-none border-t border-t-white border-opacity-25"
  >
    <div class="mx-auto w-[90%] max-w-xl">
      <div class="w-full">
        <div
          class="flex flex-col items-start w-full gap-8 md:flex-row md:gap-0"
        >
          <div class="w-full md:w-7/12">
            <div
              class="flex flex-col items-start justify-between w-full gap-8 md:flex-row md:gap-0"
            >
              <div class="w-3/5 md:w-auto">
                <img
                  src="@/assets/images/whiteLogo.png"
                  class="md:w-auto w-[130px]"
                  alt="Logo"
                />
              </div>
              <div class="w-auto md:w-8/12">
                <h6
                  class="mb-6 text-lg font-bold text-white capitalize font-inter"
                >
                  Vehicle Catalogue
                </h6>
                <ul class="p-0 list-none">
                  <li class="mb-4 text-base font-medium font-inter">
                    <router-link :to="{ name: 'panther' }">
                      Panther
                    </router-link>
                  </li>
                  <li class="mb-4 text-base font-medium font-inter">
                    <router-link :to="{ name: 'savannah' }">
                      Savannah
                    </router-link>
                  </li>
                  <li class="mb-4 text-base font-medium font-inter">
                    <router-link :to="{ name: 'sill' }"> Sill </router-link>
                  </li>
                  <li class="mb-4 text-base font-medium font-inter">
                    <router-link :to="{ name: 'sable' }"> Sable </router-link>
                  </li>
                  <li class="mb-4 text-base font-medium font-inter">
                    <router-link :to="{ name: 'javan' }"> Javan </router-link>
                  </li>
                  <li class="mb-4 text-base font-medium font-inter">
                    <a
                      href="/assets/doc/Qoray Vehicle Catalogue 2025.pdf"
                      download
                    >
                      Download Brochure
                    </a>
                  </li>
                  <!-- <li class="mb-4 text-base font-medium font-inter">
                    <router-link :to="{ name: 'home' }"> Panther </router-link>
                  </li> 
                  <li class="mb-4 text-base font-medium font-inter">
                    <router-link :to="{ name: 'home' }"> Savannah </router-link>
                  </li>
                  <li class="mb-4 text-base font-medium font-inter">
                    <router-link :to="{ name: 'home' }"> Bear </router-link>
                  </li>
                  <li class="mb-4 text-base font-medium font-inter">
                    <router-link :to="{ name: 'home' }"> Seal </router-link>
                  </li>
                  <li class="mb-4 text-base font-medium font-inter">
                    <router-link :to="{ name: 'home' }"> Atlas </router-link>
                  </li>
                  <li class="mb-4 text-base font-medium font-inter">
                    <router-link :to="{ name: 'home' }"> Pika </router-link>
                  </li>

                  <li class="mb-4 text-base font-medium font-inter">
                    <router-link :to="{ name: 'home' }"> Teak </router-link>
                  </li>
                  <li class="mb-4 text-base font-medium font-inter">
                    <router-link :to="{ name: 'home' }"> Beluga </router-link>
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
          <div class="w-full md:w-5/12">
            <div
              class="flex flex-col items-start w-full gap-8 md:flex-row md:gap-0"
            >
              <div class="w-full md:w-3/5">
                <h6
                  class="mb-6 text-lg font-bold text-white capitalize font-inter"
                >
                  Services
                </h6>
                <ul class="p-0 list-none">
                  <li class="mb-4 text-base font-medium font-inter">
                    <span>Financing </span>
                  </li>
                  <li class="mb-4 text-base font-medium font-inter">
                    <span>Energy networks </span>
                  </li>
                  <li class="mb-4 text-base font-medium font-inter">
                    <span>Switch </span>
                  </li>
                  <li class="mb-4 text-base font-medium font-inter">
                    <span>Manufacturing, Assembly and Sales </span>
                  </li>
                  <li class="mb-4 text-base font-medium font-inter">
                    <span>Mobility as a Service </span>
                  </li>
                  <li class="mb-4 text-base font-medium font-inter">
                    <span>Tour with Qoray </span>
                  </li>
                </ul>
              </div>
              <div>
                <h6
                  class="mb-6 text-lg font-bold text-white capitalize font-inter"
                >
                  More
                </h6>
                <ul class="p-0 list-none">
                  <li class="mb-4 text-base font-medium font-inter">
                    <router-link :to="{ name: 'faq' }">FAQ </router-link>
                  </li>
                  <li class="mb-4 text-base font-medium font-inter">
                    <!-- <router-link :to="{ name: 'home' }"
                      > -->
                    <span class="cursor-pointer" @click="contactUs">
                      Contact Us
                    </span>
                    <!-- </router-link> -->
                  </li>
                  <li class="mb-4 text-base font-medium font-inter">
                    <!-- <router-link :to="{ name: 'home' }"
                      > -->
                    <a href="tel:+2348151491881 " class="cursor-pointer">
                      +234 815 149 1881
                    </a>
                    <!-- </router-link> -->
                  </li>
                  <!-- <li class="mb-4 text-base font-medium font-inter">
                    <router-link :to="{ name: 'home' }">Media </router-link>
                  </li>
                  <li class="mb-4 text-base font-medium font-inter">
                    <router-link :to="{ name: 'home' }">News </router-link>
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center w-full gap-6 mt-12 mb-16">
        <a href="https://www.instagram.com/qoraymobility/" target="_blank">
          <img src="@/assets/svgs/instagram.svg" alt="" />
        </a>
        <!-- <a href="" target="_blank">
          <img src="@/assets/svgs/threads.svg" alt="" />
        </a> -->
        <!-- <a href="" target="_blank">
          <img src="@/assets/svgs/linkedin.svg" alt="" />
        </a> -->
        <a href="https://x.com/qoraymobility" target="_blank">
          <img src="@/assets/svgs/twitter.svg" alt="" />
        </a>
        <a href="https://www.youtube.com/@QorayMobility" target="_blank">
          <img src="@/assets/svgs/youtube.svg" alt="" />
        </a>
        <!-- <a href="" target="_blank">
          <img src="@/assets/svgs/facebook.svg" alt="" />
        </a> -->
      </div>
      <div
        class="flex flex-wrap items-center justify-center w-full md:flex-nowrap md:justify-start gap-y-4"
      >
        <p class="font-inter pr-6 border-r border-[#BEBBBB]">
          &copy;{{ year }} Qoray. All rights reserved
        </p>
        <p class="font-inter px-6 border-r text-center border-[#BEBBBB]">
          <router-link :to="{ name: 'terms' }"> Terms of Use</router-link>
        </p>
        <p class="font-inter px-6 text-center border-[#BEBBBB]">
          <router-link :to="{ name: 'privacy' }"> Privacy Policy</router-link>
        </p>
        <!-- <p class="font-inter px-6 border-l text-center border-[#BEBBBB]">
          <router-link :to="{ name: 'home' }"> Cookie Settings</router-link>
        </p> -->
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
  methods: {
    contactUs() {
      this.$store.dispatch("contactUs");
    },
  },
};
</script>

<style></style>
